import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LandingComponent } from './views/landing/landing.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(
      environment.production ? 'G-Q8NZ3BNNKT' : ''
    ),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
